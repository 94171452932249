body{
    background: url(./main_bg.png);
  
    background-position: top center;
    background-size: 100% auto;
}
*, :after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 0 solid;
}
a {
    color: inherit;
    text-decoration: inherit;
}
.clear{
    clear: both;
}
.top-section {
    width: 100%;
    height: 82px;
    z-index: 9999;
    margin: 0 auto;
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.top-section .top-section-wrapper {
    height: 82px;
    line-height: 81px;
    font-size: 0;
    margin: 0 auto;
    padding-left: 36px;
    padding-right: 36px;
}
.top-section .top-section-wrapper-left {
    width: 70%;
    height: 82px;
    font-size: 14px;
    text-align: left;
    line-height: 1;
}
.inlineblock-middle, .inlineblock_top, .inlineblock_bottom {
    display: inline-block;
    *zoom: 1;
    *display: inline;
    _zoom: 1;
    _display: inline;
    vertical-align: middle;
}
.top-section a {
    color: #666;
}
.top-section .top-section-wrapper-left img {
    height: 49px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 12px;
}
.top-section .top-section-wrapper-leftnav {
    width: 80%;
    font-size: 14px;
    text-align: left;
    margin-left: 20px;
}
.top-section .top-section-wrapper-leftnav .top-section-wrapper-leftnav-item {
    width: 15%;
    height: 82px;
    font-size: 16px;
    text-align: center;
    border-top: 2px solid transparent;
    line-height: 82px;
}
.top-section .top-section-wrapper-leftnav .top-section-wrapper-leftnav-item span{
    margin-left: 6px;
}
.sign-button{
    cursor: pointer;
    color:#8E0100;
    border-radius: 6px;
    width: 390px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    margin:0 auto;
    font-size: 14px;
}
.sign-button.blue{
    border:1px solid #8E0100;
    text-align: center;
    color:#8E0100;
}

.login-bottom{
    margin-top:20px;
}
.login-bottom-wrapper{
    padding-top:20px;
    color: #666;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 13px;
}
.login-bottom-wrapper-a{
    color: #CFA972;
    padding-left:10px;
}

.footer{
    color:#666;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    position: absolute;
    bottom: 60px;
}

.sign-icon{
    width: 180px;
    height: 180px;
    display: block;
    margin: 0 auto 100px;
}


.provision-addr{
    padding-top:20px;
    color: #fff;
    font-size: 15px;
    margin-bottom:20px;
}
.provision-bottom{
    margin-top:20px;
    margin-bottom:60px;
}
.provision-bottom-wrapper{
    padding-top:20px;
    color: #6b7185;
    border-top: 1px solid #3a3f50;
    font-size: 13px;
}

.top-section-wrapper-left-btn{
    display: none;
    width: 30%;
    height: 82px;
    font-size: 22px;
    color: #fff;
    text-align: right;
}
.top-section-wrapper-left-btn-popup{
    background-color: #1a1326;
    width: 38%;
    position: fixed;
    top: 82px;
    right: 0;
    padding: 0 5%;
}
.top-section-wrapper-left-btn-popup .top-section-wrapper-left-btn-popupitem{
    width: 100%;
    height: 3rem;
    font-size: 14px;
    text-align: center;
    line-height: 3rem;
    display: block;
    text-align: left;
}
.top-section-wrapper-left-btn-popup .top-section-wrapper-left-btn-popupitem span{
    margin-left: 0.6rem;
}
/* 大型设备(大台式电脑，1200px 起)*/
@media screen and (min-width:1200px) {

}
 /* 中型设备(台式电脑，992px 起)*/
@media screen and (min-width:992px) and (max-width:1200px) { 

}
 
/* 小型设备(平板电脑，768px 起)*/
@media screen and (min-width:768px) and (max-width:992px) {
    .top-section-wrapper-leftnav{
        display: none;
    }
    .top-section-wrapper-left-btn{
        display: inline-block;
    }
    .sign-icon{
        width: 6rem;
        height: 6rem;
        margin-bottom:3rem;
    }
    .sign-button{
        width: 80%;
        margin: 0 auto;
    }
    .login-bottom{
        width: 80%;
        margin: 20px auto 0 ;
    }
}
 
/* 超小设备(手机，小于 768px)*/
@media screen and (max-width:768px) {
    .top-section-wrapper-leftnav{
        display: none;
    }
    .top-section-wrapper-left-btn{
        display: inline-block;
    }
    .sign-icon{
        width: 6rem;
        height: 6rem;
        margin-bottom:3rem;
    }
    .sign-button{
        width: 80%;
        margin: 0 auto;
    }
    .login-bottom{
        width: 80%;
        margin: 20px auto 0 ;
    }
}
