.alert-con {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 11;
  }
  .alert-context {
    background-color: #fff;
    border-radius: 10px;
    height: 166px;
    width: 15%;
    margin: 15% auto 0;
  }
  .alert-context .alert-content-detail {
    text-align: center;
    color: #333;
    font-size: 14px;
    height: 40px;
    line-height: 1.6;
    width: 80%;
    margin: 0 auto;
    padding: 40px 0;
  }
  .alert-context .comfirm {
    border-top: 1PX solid #eee;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #666;
    cursor: pointer;
  }
  .alert-enter {
    opacity: 0;
  }
  .alert-enter.alert-enter-active {
    transition: all 300ms;
    opacity: 1;
  }
  .alert-leave {
    opacity: 1;
  }
  .alert-leave.alert-leave-active {
    transition: all 300ms;
    opacity: 0;
  }